import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import createEmotionCache from "./utils/createEmotionCache";

import { AuthProvider } from "./contexts/CognitoContext";
import { muiX } from "./config";

import { LicenseInfo } from "@mui/x-license";
import { wrapCreateBrowserRouter } from "@sentry/react";

if (muiX.license) {
  LicenseInfo.setLicenseKey(muiX.license);
}

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);
const routerSentry = sentryCreateBrowserRouter(routes);
const routerRegular = createBrowserRouter(routes);

function App() {
  let router;
  // Disable Sentry on development
  if (import.meta.env.MODE === "development") {
    router = routerRegular;
  } else {
    router = routerSentry;
  }
  const { theme } = useTheme();

  return (
    <CacheProvider value={createEmotionCache()}>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | Infra Portal"
          defaultTitle="Infra Portal - Fast and easy network deployment"
        />
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
        <MuiThemeProvider theme={createTheme(theme)}>
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </MuiThemeProvider>
        {/* </LocalizationProvider> */}
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
