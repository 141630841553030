import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import App from "./App";

import { ThemeProvider } from "./contexts/ThemeContext";
import { isAxiosError } from "axios";

export function getNonce() {
  const csp_nonce: HTMLElement = document.querySelector(
    'meta[property="csp-nonce"]',
  )!;
  if (csp_nonce === null) {
    throw new Error("meta csp-nonce doesn't exist");
  }
  return csp_nonce.nonce;
}

const getEnvironment = () => {
  if (import.meta.env.MODE === "development") {
    return "development";
  } else if (window.location.host.startsWith("acc")) {
    return "staging";
  } else if (import.meta.env.MODE === "production") {
    return "production";
  } else {
    return "edgeCase_Not_Possible";
  }
};

// Disable Sentry on development
if (import.meta.env.MODE !== "development") {
  Sentry.init({
    environment: getEnvironment(),
    dsn: "https://eb44191eafb7f43549ac7e54f16ae0d3@o4507018630660096.ingest.us.sentry.io/4507035781627904",
    tunnel: "/api/t",
    beforeSend(event, hint) {
      if (isAxiosError(hint.originalException)) {
        if (hint.originalException.response) {
          const contexts = { ...event.contexts };
          contexts.Axios = { Response: hint.originalException.response };
          event.contexts = contexts;
          return event;
        }
      }
      return event;
    },
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),

      Sentry.replayCanvasIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2, //  Capture 20% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      "acc.infraportal.leeps.nl",
      "infraportal.leeps.nl",
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  Sentry.setTag("runtime.name", "React");
}

import("@sentry/react").then((lazyLoadedSentry) => {
  Sentry.addIntegration(
    lazyLoadedSentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  );
  Sentry.addIntegration(
    lazyLoadedSentry.feedbackIntegration({
      styleNonce: getNonce(),
      scriptNonce: getNonce(),
      colorScheme: "system",
      isNameRequired: true,
      useSentryUser: {},
    }),
  );
});

const container = document.getElementById("root");
const root = createRoot(container!);

if (import.meta.env.MODE === "production")
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = function no_console() {};

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
);
