import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { CognitoJwtVerifier } from "aws-jwt-verify";
import { fetchAuthSession } from "aws-amplify/auth";
import { getCookie } from "./cookie";

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = window.location.origin;

const refreshTokens = async (
  originalRequestConfig: InternalAxiosRequestConfig<any>,
) => {
  const amplifyConf = getCookie("amplifyConf");
  if (amplifyConf) {
    return await fetchAuthSession().then((user) => {
      if (user.tokens !== undefined) {
        const token = user.tokens.idToken;
        if (token !== undefined) {
          const amplifyConfParsed = JSON.parse(amplifyConf);
          const userPoolId: string =
            amplifyConfParsed.Auth.Cognito.userPoolId.toString();
          const clientId: string =
            amplifyConfParsed.Auth.Cognito.userPoolClientId.toString();

          const verifier = CognitoJwtVerifier.create({
            userPoolId: userPoolId,
            tokenUse: "id",
            clientId: clientId,
            graceSeconds: 0,
          });
          return verifier.verify(token.toString()).then(async () => {
            // console.log("Token is valid. Payload:", payload);
            const bearerToken = "Bearer " + token.toString();
            axiosInstance.defaults.headers.common.Authorization = bearerToken;
            originalRequestConfig.headers.Authorization = bearerToken;

            return await axiosInstance.request(originalRequestConfig);
          });
        }
      }
    });
  }
};

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      const amplifyConf = getCookie("amplifyConf");
      if (amplifyConf) {
        const originalRequestConfig = error.config;
        if (originalRequestConfig) {
          const result = await refreshTokens(originalRequestConfig);
          return Promise.resolve(result);
        }
      } else {
        console.error(error);
        return Promise.reject(403);
      }
    } else {
      if (error.code === "ERR_CANCELED") {
        return Promise.reject(499);
      }
      return Promise.reject(error);
    }
  },
);

export default axiosInstance;
