import createCache from "@emotion/cache";
import { getNonce } from "..";

const createEmotionCache = () => {
  return createCache({
    key: "css",
    prepend: true,
    nonce: getNonce(),
  });
};

export default createEmotionCache;
