import { Outlet, useLocation } from "react-router-dom";
import styled from "@emotion/styled";

import { CssBaseline } from "@mui/material";

import GlobalStyle from "../components/GlobalStyle";
import { AuthType } from "../types/auth";

const Root = styled.div`
  margin: 0 auto;
  display: flex;
  min-height: 100%;
`;

export const Auth = ({ children }: AuthType) => {
  const { pathname } = useLocation();
  return (
    <Root
      style={{
        maxWidth: pathname !== "/auth/sign-in" ? "520px" : "",
        justifyContent: pathname !== "/auth/sign-in" ? "center" : "",
        alignItems: pathname !== "/auth/sign-in" ? "center" : "",
        flexDirection: pathname !== "/auth/sign-in" ? "column" : "unset",
      }}
    >
      <CssBaseline />
      <GlobalStyle />
      {children ?? <Outlet />}
    </Root>
  );
};
