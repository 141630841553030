// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

import { RouteObject } from "react-router-dom";
import { Auth } from "./layouts/Auth";
import { Page404 } from "./pages/auth/Page404";

const routes: RouteObject[] = [
  {
    errorElement:
      import.meta.env.MODE !== "development" ? (
        <Auth>
          <Page404 />
        </Auth>
      ) : null,
    children: [
      {
        path: "/",
        async lazy() {
          const { AuthGuard } = await import("./components/guards/AuthGuard");
          const { Default } = await import("./pages/dashboards/Default");
          return {
            element: (
              <AuthGuard>
                <Default />
              </AuthGuard>
            ),
          };
        },
      },
      {
        path: "deploy",
        children: [
          {
            index: true,
            lazy: () => import("./layouts/Auth404"),
          },
          {
            children: [
              {
                path: "network",
                async lazy() {
                  const { AuthGuard } = await import(
                    "./components/guards/AuthGuard"
                  );
                  const { NetworkDeploy } = await import(
                    "./pages/pages/NetworkDeploy/NetworkDeploy"
                  );
                  return {
                    element: (
                      <AuthGuard>
                        <NetworkDeploy />
                      </AuthGuard>
                    ),
                  };
                },
              },
              {
                path: "camera",
                async lazy() {
                  const { AuthGuard } = await import(
                    "./components/guards/AuthGuard"
                  );
                  const { CameraDeploy } = await import(
                    "./pages/pages/CameraDeploy/CameraDeploy"
                  );
                  return {
                    element: (
                      <AuthGuard>
                        <CameraDeploy />
                      </AuthGuard>
                    ),
                  };
                },
              },
            ],
          },
        ],
      },
      {
        path: "network",
        children: [
          {
            index: true,
            lazy: () => import("./layouts/Auth404"),
          },
          {
            children: [
              {
                path: "status",
                async lazy() {
                  const { AuthGuard } = await import(
                    "./components/guards/AuthGuard"
                  );
                  const { NetworkStatus } = await import(
                    "./pages/pages/NetworkStatus/NetworkStatus"
                  );
                  return {
                    element: (
                      <AuthGuard>
                        <NetworkStatus />
                      </AuthGuard>
                    ),
                  };
                },
              },
              {
                path: "report",
                async lazy() {
                  const { AuthGuard } = await import(
                    "./components/guards/AuthGuard"
                  );
                  const { NetworkReport } = await import(
                    "./pages/pages/NetworkReport/NetworkReport"
                  );
                  return {
                    element: (
                      <AuthGuard>
                        <NetworkReport />
                      </AuthGuard>
                    ),
                  };
                },
              },
              {
                path: "dhcp",
                async lazy() {
                  const { AuthGuard } = await import(
                    "./components/guards/AuthGuard"
                  );
                  const { DhcpReservation } = await import(
                    "./pages/pages/DhcpReservation/DhcpReservation"
                  );
                  return {
                    element: (
                      <AuthGuard>
                        <DhcpReservation />
                      </AuthGuard>
                    ),
                  };
                },
              },
              {
                path: "subnet",
                async lazy() {
                  const { AuthGuard } = await import(
                    "./components/guards/AuthGuard"
                  );
                  const { SubnetOverview } = await import(
                    "./pages/pages/SubnetOverview/SubnetOverview"
                  );
                  return {
                    element: (
                      <AuthGuard>
                        <SubnetOverview />
                      </AuthGuard>
                    ),
                  };
                },
              },
              {
                path: "WakeOnLan",
                async lazy() {
                  const { AuthGuard } = await import(
                    "./components/guards/AuthGuard"
                  );
                  const { WakeOnLan } = await import(
                    "./pages/pages/WakeOnLan/WakeOnLan"
                  );
                  return {
                    element: (
                      <AuthGuard>
                        <WakeOnLan />
                      </AuthGuard>
                    ),
                  };
                },
              },
            ],
          },
          {
            path: "camera",
            children: [
              {
                index: true,
                lazy: () => import("./layouts/Auth404"),
              },
              {
                children: [
                  {
                    path: "events",
                    async lazy() {
                      const { AuthGuard } = await import(
                        "./components/guards/AuthGuard"
                      );
                      const { CameraEvents } = await import(
                        "./pages/pages/CameraEvents/CameraEvents"
                      );
                      return {
                        element: (
                          <AuthGuard>
                            <CameraEvents />
                          </AuthGuard>
                        ),
                      };
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "device",
        children: [
          {
            index: true,
            lazy: () => import("./layouts/Auth404"),
          },
          {
            children: [
              {
                path: "add",
                async lazy() {
                  const { AuthGuard } = await import(
                    "./components/guards/AuthGuard"
                  );
                  const { AddDevice } = await import(
                    "./pages/pages/AddDevice/AddDevice"
                  );
                  return {
                    element: (
                      <AuthGuard>
                        <AddDevice />
                      </AuthGuard>
                    ),
                  };
                },
              },
              {
                path: "replace",
                async lazy() {
                  const { AuthGuard } = await import(
                    "./components/guards/AuthGuard"
                  );
                  const { ReplaceDevice } = await import(
                    "./pages/pages/ReplaceDevice/ReplaceDevice"
                  );
                  return {
                    element: (
                      <AuthGuard>
                        <ReplaceDevice />
                      </AuthGuard>
                    ),
                  };
                },
              },
            ],
          },
        ],
      },
      {
        path: "auth",
        async lazy() {
          const { Auth } = await import("./layouts/Auth");
          return { Component: Auth };
        },
        children: [
          {
            index: true,
            async lazy() {
              const { Page404 } = await import("./pages/auth/Page404");
              return { Component: Page404 };
            },
          },
          {
            path: "sign-in",
            children: [
              {
                // In case tenant IS NOT specified in url, display enter email form
                index: true,
                async lazy() {
                  const { SignInNoTenant } = await import(
                    "./pages/auth/SignInNoTenant"
                  );
                  return { Component: SignInNoTenant };
                },
              },
              // In case tenant IS specified in url, search in database and redirect to Cognito
              {
                path: ":t",
                async lazy() {
                  const { SignInTenant } = await import(
                    "../src/components/auth/SignInTenant"
                  );
                  return { Component: SignInTenant };
                },
              },
            ],
          },
          // TODO: Add Admin Login form
          // {
          //   path: "admin",
          //   children: [
          //     {
          //       path: "sign-in",
          //       children: [
          //         {
          //           // Display the Admin login form
          //           index: true,
          //           element: <SignIn />,
          //         },
          //       ],
          //     },
          //   ],
          // },
        ],
      },
    ],
  },
];

export default routes;
