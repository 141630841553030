import axios from "../utils/axios";
import * as Sentry from "@sentry/react";

import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
} from "react";

import {
  ActionMap,
  AuthState,
  AuthUser,
  CognitoContextType,
} from "../types/auth";

import { Amplify, ResourcesConfig } from "aws-amplify";
import { CookieStorage, Hub } from "aws-amplify/utils";
import {
  cognitoUserPoolsTokenProvider,
  signInWithRedirect,
} from "aws-amplify/auth/cognito";

import { AuthSession, fetchAuthSession } from "aws-amplify/auth";
import { CognitoJwtVerifier } from "aws-jwt-verify";
import { CognitoIdTokenPayload } from "aws-jwt-verify/jwt-model";
import { eraseCookie, getCookie, setCookie } from "../utils/cookie";
import { isAxiosError } from "axios";

const INITIALIZE = "INITIALIZE";
const SIGN_OUT = "SIGN_OUT";

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

interface AuthActionTypes {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [SIGN_OUT]: undefined;
}

type CognitoActions =
  ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>];

interface TenantResult {
  name: string;
  logo?: string;
  managerTenant: boolean;
  powerBiOnlyTenant: boolean;
}

interface getSessionResolveType {
  user: AuthSession;
  headers: {
    Authorization: string;
  };
}

const reducer = (state: AuthState, action: CognitoActions) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }
  if (action.type === SIGN_OUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/.exec(
      window.location.hostname,
    ),
);

const AuthContext = createContext<CognitoContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const amplifyConf = getCookie("amplifyConf");
  if (amplifyConf != null) {
    try {
      Amplify.configure(JSON.parse(amplifyConf));
      cognitoUserPoolsTokenProvider.setKeyValueStorage(
        // Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        new CookieStorage({
          // Cookie domain (only required if cookieStorage is provided)
          domain: isLocalhost ? "localhost" : window.location.hostname,
          // Cookie path
          path: "/",
          // Cookie expiration in days
          expires: 365,
          // See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
          sameSite: "strict",
          // Cookie secure flag
          // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
          secure: true,
        }),
      );
    } catch (error) {
      eraseCookie("TenantName");
      eraseCookie("TenantLogo");
      eraseCookie("ChosenTenant");
      eraseCookie("AvailableTenants");
      eraseCookie("ManagerTenant");
      eraseCookie("PowerBiOnlyTenant");
      eraseCookie("cognito");
      eraseCookie("amplifyConf");
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const getUserAttributes = useCallback(
    (currentUser: CognitoIdTokenPayload): Promise<Record<string, any>> => {
      return new Promise((resolve, reject) => {
        const results: Record<string, any> = {};

        if (!currentUser["custom:roles"]) {
          return reject(new Error("No custom roles found"));
        }
        if (typeof currentUser["custom:roles"] !== "string") {
          return reject(new Error("Invalid custom roles format"));
        }

        const custom_roles = currentUser["custom:roles"].toString();

        let TenantLogo = getCookie("TenantLogo");
        let TenantName = getCookie("TenantName");
        let ChosenTenant = getCookie("ChosenTenant");
        let ManagerTenant = getCookie("ManagerTenant");
        let PowerBiOnlyTenant = getCookie("PowerBiOnlyTenant");
        let AvailableTenants = getCookie("AvailableTenants");

        if (TenantName == null) {
          eraseCookie("TenantName");
          eraseCookie("TenantLogo");
          eraseCookie("ChosenTenant");
          eraseCookie("AvailableTenants");
          eraseCookie("ManagerTenant");
          eraseCookie("PowerBiOnlyTenant");
          TenantName = getCookie("TenantName");
          TenantLogo = getCookie("TenantLogo");
          ChosenTenant = getCookie("ChosenTenant");
          AvailableTenants = getCookie("AvailableTenants");
          ManagerTenant = getCookie("ManagerTenant");
          PowerBiOnlyTenant = getCookie("PowerBiOnlyTenant");
        }

        let ignoreGetAvailableTenants = false;

        const abortGetAvailableTenantsController = new AbortController();
        const fetchAvailableTenants = async () => {
          try {
            const response = await axios.get(
              "/api/Tenant/GetAvailableTenants",
              {
                signal: abortGetAvailableTenantsController.signal,
              },
            );
            if (response.status === 200) {
              if (!ignoreGetAvailableTenants) {
                const availableTenantsResult: string[] = response.data;

                setCookie(
                  "AvailableTenants",
                  JSON.stringify(availableTenantsResult),
                  1,
                );
                AvailableTenants = getCookie("AvailableTenants");

                if (ChosenTenant == null) {
                  setCookie(
                    "ChosenTenant",
                    availableTenantsResult[0].split(" ").join("_"),
                    1,
                  );
                  ChosenTenant = getCookie("ChosenTenant");
                }
              }
            } else {
              console.log("Fault:", response);
            }
          } catch (error: any) {
            if (error !== 499 && error !== 403) {
              Sentry.captureException(error);
              console.error("Error: ", error);
            }
            reject(error);
            handleSignOut();
          }
        };

        let ignoreGetUserInfo = false;

        const abortGetUserInfoController = new AbortController();
        const fetchUserInfo = async () => {
          if (
            ChosenTenant != null &&
            ChosenTenant.split("_").join(" ") !== TenantName
          ) {
            eraseCookie("TenantName");
            eraseCookie("TenantLogo");
            TenantName = getCookie("TenantName");
            TenantLogo = getCookie("TenantLogo");
          }

          if (
            TenantLogo == null ||
            TenantName == null ||
            ManagerTenant == null ||
            PowerBiOnlyTenant == null
          ) {
            try {
              const response = await axios.get("/api/Tenant/getUserInfo", {
                signal: abortGetUserInfoController.signal,
              });
              if (response.status === 200) {
                if (!ignoreGetUserInfo) {
                  const tenantResult: TenantResult = response.data;

                  ChosenTenant = getCookie("ChosenTenant");
                  if (ChosenTenant !== null) {
                    const AvailableTenantsCookie =
                      getCookie("AvailableTenants");
                    if (AvailableTenantsCookie !== null) {
                      AvailableTenants = JSON.parse(AvailableTenantsCookie);
                      if (
                        AvailableTenants?.includes(
                          ChosenTenant.split("_").join(" "),
                        )
                      ) {
                        setCookie("TenantName", tenantResult.name, 1);
                        setCookie("TenantLogo", tenantResult.logo ?? "", 1);
                        setCookie(
                          "ManagerTenant",
                          tenantResult.managerTenant ? "true" : "false",
                          1,
                        );
                        setCookie(
                          "PowerBiOnlyTenant",
                          tenantResult.powerBiOnlyTenant ? "true" : "false",
                          1,
                        );
                        TenantLogo = getCookie("TenantLogo");
                        TenantName = getCookie("TenantName");
                        ManagerTenant = getCookie("ManagerTenant");
                        PowerBiOnlyTenant = getCookie("PowerBiOnlyTenant");

                        if (TenantName == null) {
                          reject(
                            new Error(
                              "TenantName is null after fetching user info",
                            ),
                          );
                          return handleSignOut();
                        }

                        results.name = currentUser.given_name;
                        results.surname = currentUser.family_name;
                        results.fullName = results.name + " " + results.surname;
                        results.email = currentUser.name;
                        results.logo = TenantLogo;
                        results.tenant = TenantName;
                        results.role = custom_roles.split("-")[1];
                        results["custom:roles"] = custom_roles;
                        results.managerTenant = ManagerTenant === "true";
                        results.powerBiOnlyTenant =
                          PowerBiOnlyTenant === "true";

                        resolve(results);
                      } else {
                        reject(new Error("Chosen tenant not available"));
                        return handleSignOut();
                      }
                    }
                  }
                }
              } else {
                console.log("Fault:", response);
              }
            } catch (error: any) {
              if (error !== 499 && error !== 403) {
                Sentry.captureException(error);
                console.error("Error: ", error);
              }
              reject(error);
            }
          } else {
            results.name = currentUser.given_name;
            results.surname = currentUser.family_name;
            results.fullName = results.name + " " + results.surname;
            results.email = currentUser.name;
            results.logo = TenantLogo;
            results.tenant = TenantName;
            results.role = custom_roles.split("-")[1];
            results["custom:roles"] = custom_roles;
            results.managerTenant = ManagerTenant === "true";
            results.powerBiOnlyTenant = PowerBiOnlyTenant === "true";

            resolve(results);
          }
        };

        fetchAvailableTenants().then(fetchUserInfo).catch(reject);

        return () => {
          abortGetAvailableTenantsController.abort();
          ignoreGetAvailableTenants = true;
          ignoreGetUserInfo = true;
          abortGetUserInfoController.abort();
        };
      });
    },
    [],
  );

  const getSession = useCallback(
    () =>
      new Promise<getSessionResolveType>((resolve, reject) => {
        const amplifyConf = getCookie("amplifyConf");
        if (amplifyConf) {
          fetchAuthSession()
            .then((user: AuthSession) => {
              if (user.tokens !== undefined) {
                const token = user.tokens.idToken;

                if (token !== undefined) {
                  const amplifyConfParsed = JSON.parse(amplifyConf);

                  const userPoolId: string =
                    amplifyConfParsed.Auth.Cognito.userPoolId.toString();

                  const clientId: string =
                    amplifyConfParsed.Auth.Cognito.userPoolClientId.toString();

                  const verifier = CognitoJwtVerifier.create({
                    userPoolId: userPoolId,
                    tokenUse: "id",
                    clientId: clientId,
                    graceSeconds: 0,
                  });

                  verifier
                    .verify(token.toString())
                    .then((payload) => {
                      // console.log("Token is valid. Payload:", payload);
                      const bearerToken = "Bearer " + token.toString();
                      axios.defaults.headers.common.Authorization = bearerToken;

                      getUserAttributes(payload).then((attributes) => {
                        Sentry.setUser({ username: attributes.tenant });

                        dispatch({
                          type: INITIALIZE,
                          payload: {
                            isAuthenticated: true,
                            user: attributes,
                          },
                        });

                        resolve({
                          user,
                          headers: { Authorization: bearerToken },
                        });
                      });
                    })
                    .catch((error: any) => {
                      if (error !== 499 && error !== 403) {
                        Sentry.captureException(error);
                        console.log("Token not valid!");
                      }
                      reject(error);
                    });
                }
              } else {
                eraseCookie("amplifyConf");
              }
            })
            .catch((error: any) => {
              reject(error);
            });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }),
    [getUserAttributes],
  );

  const initialize = useCallback(async () => {
    try {
      await getSession();
    } catch (error: any) {
      if (error !== 499 && error !== 403) {
        Sentry.captureException(error);
      }
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [getSession]);

  useEffect(() => {
    initialize();
    const hubListenerCancelToken = Hub.listen(
      "auth",
      ({ payload: { event } }) => {
        switch (event) {
          case "signInWithRedirect":
          case "signedIn":
          case "customOAuthState": {
            getSession().then((e) => {
              return e;
            });
            break;
          }
          case "signInWithRedirect_failure":
          case "tokenRefresh_failure":
          case "signedOut": {
            eraseCookie("TenantName");
            eraseCookie("TenantLogo");
            eraseCookie("ChosenTenant");
            eraseCookie("AvailableTenants");
            eraseCookie("ManagerTenant");
            eraseCookie("PowerBiOnlyTenant");
            eraseCookie("cognito");
            eraseCookie("amplifyConf");
            break;
          }
          default:
            break;
        }
      },
    );
    return () => {
      hubListenerCancelToken();
    };
  }, [getSession, initialize]);

  const signIn = useCallback(
    (emailDomain: string) =>
      new Promise((resolve, reject) => {
        axios
          .get(`/api/Tenant/GetLogin/${emailDomain}`)
          .then((response) => {
            if (response.status === 200) {
              return response.data;
            }
          })
          .catch((error) => {
            if (isAxiosError(error)) {
              if (error.response?.status !== 404) {
                console.error("Axios Error: ", error);
                Sentry.captureException(error);
              }
              if (error.response?.status === 404) {
                reject(error);
              }
            } else {
              if (error !== 499 && error !== 403) {
                Sentry.captureException(error);
                console.error(error);
              }
            }
          })
          .then((getLogin) => {
            if (getLogin) {
              const amplifyConf: ResourcesConfig = {
                Auth: {
                  Cognito: {
                    // Amazon Cognito User Pool ID
                    userPoolId: getLogin.cognitoUserPoolID,

                    // Amazon Cognito App Client ID (26-char alphanumeric string)
                    userPoolClientId: getLogin.cognitoClientID,

                    loginWith: {
                      // Hosted UI configuration
                      oauth: {
                        domain: getLogin.cognitoDomain,
                        scopes: ["openid"],
                        redirectSignIn: isLocalhost
                          ? ["https://localhost:3000/"]
                          : [window.location.origin + "/"],
                        redirectSignOut: isLocalhost
                          ? ["https://localhost:3000/auth/sign-in"]
                          : [window.location.origin + "/auth/sign-in"],
                        responseType: "code",
                      },
                    },
                  },
                },
              };
              setCookie("amplifyConf", JSON.stringify(amplifyConf), 365);

              Amplify.configure(amplifyConf);

              cognitoUserPoolsTokenProvider.setKeyValueStorage(
                // Configuration for cookie storage
                // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
                new CookieStorage({
                  // Cookie domain (only required if cookieStorage is provided)
                  domain: isLocalhost ? "localhost" : window.location.hostname,
                  // Cookie path
                  path: "/",
                  // Cookie expiration in days
                  expires: 365,
                  // See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
                  sameSite: "strict",
                  // Cookie secure flag
                  // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
                  secure: true,
                }),
              );

              resolve(signInWithRedirect({ provider: { custom: "AzureAD" } }));
            }
          })
          .catch((error) => {
            if (error !== 499 && error !== 403 && error !== 404) {
              Sentry.captureException(error);
              console.error(error);
            }
            reject(error);
          });
      }),
    [],
  );

  const handleSignOut = () => {
    import("aws-amplify/auth/cognito").then(({ signOut }) => {
      signOut().then(() => {
        dispatch({ type: SIGN_OUT });
      });
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "cognito",
        user: {
          displayName: state?.user?.name || "Undefined",
          ...state.user,
        },
        signIn,
        signOut: handleSignOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
