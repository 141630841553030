import { Global, css } from "@emotion/react";
import { useLocation } from "react-router-dom";

const GlobalStyle = (props: any) => {
  const { pathname } = useLocation();

  return (
    <Global
      {...props}
      styles={css`
        html,
        body,
        #root {
          height: 100%;
          ${pathname === "/auth/sign-in"
            ? `background:
          linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
          url("../../static/img/unsplash/unsplash-login.webp") no-repeat
            center fixed;
        background-size: cover;`
            : ""}
        }

        body {
          margin: 0;
        }

        .MuiCardHeader-action .MuiIconButton-root {
          padding: 4px;
          width: 28px;
          height: 28px;
        }

        body > iframe {
          pointer-events: none;
        }
      `}
    />
  );
};

export default GlobalStyle;
